// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-programme-js": () => import("./../../../src/pages/programme.js" /* webpackChunkName: "component---src-pages-programme-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-sustainability-framework-js": () => import("./../../../src/pages/sustainability-framework.js" /* webpackChunkName: "component---src-pages-sustainability-framework-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-templates-brand-directory-js": () => import("./../../../src/templates/brand-directory.js" /* webpackChunkName: "component---src-templates-brand-directory-js" */),
  "component---src-templates-brand-profile-js": () => import("./../../../src/templates/brand-profile.js" /* webpackChunkName: "component---src-templates-brand-profile-js" */),
  "component---src-templates-directory-js": () => import("./../../../src/templates/directory.js" /* webpackChunkName: "component---src-templates-directory-js" */),
  "component---src-templates-education-hub-js": () => import("./../../../src/templates/education-hub.js" /* webpackChunkName: "component---src-templates-education-hub-js" */),
  "component---src-templates-education-hub-page-js": () => import("./../../../src/templates/education-hub-page.js" /* webpackChunkName: "component---src-templates-education-hub-page-js" */),
  "component---src-templates-guide-js": () => import("./../../../src/templates/guide.js" /* webpackChunkName: "component---src-templates-guide-js" */),
  "component---src-templates-guide-page-js": () => import("./../../../src/templates/guide-page.js" /* webpackChunkName: "component---src-templates-guide-page-js" */),
  "component---src-templates-join-as-a-brand-js": () => import("./../../../src/templates/join-as-a-brand.js" /* webpackChunkName: "component---src-templates-join-as-a-brand-js" */),
  "component---src-templates-join-as-an-expert-js": () => import("./../../../src/templates/join-as-an-expert.js" /* webpackChunkName: "component---src-templates-join-as-an-expert-js" */),
  "component---src-templates-join-the-community-js": () => import("./../../../src/templates/join-the-community.js" /* webpackChunkName: "component---src-templates-join-the-community-js" */),
  "component---src-templates-profile-js": () => import("./../../../src/templates/profile.js" /* webpackChunkName: "component---src-templates-profile-js" */)
}

